import React from "react";
import "../CSS/Dashboard.css";
import { Link, Outlet, useLocation } from "react-router-dom";

export const Dashboard = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="main_routes">
        <div className="nav">
          <Link
            className={`link link-dashboard ${
              pathname === "/dashboard/pending-withdrawals"
                ? "link-active"
                : undefined
            }`}
            to="pending-withdrawals"
          >
            {" "}
            Pending Withdrawals
          </Link>
          <Link
            className={`link link-dashboard ${
              pathname === "/dashboard/pending-deposit"
                ? "link-active"
                : undefined
            }`}
            to="pending-deposit"
          >
            Pending Deposits
          </Link>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
