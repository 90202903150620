import axios from "axios";
import { API_URL } from "../config/env";

class WithdrawService {
  static get_user_withdraws = (token) => {
    return axios.get(`${API_URL}/withdrawals`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static change_withdrawal_status = (token, data) => {
    return axios.post(`${API_URL}/withdrawals/change-status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export default WithdrawService;
