import { useState } from "react";
import "../CSS/create-profit.css";

const CompleteTradeModal = ({ handleOk, handleCancel }) => {
  const [form, setForm] = useState({});

  const onChange = (key, val) => setForm((prev) => ({ ...prev, [key]: val }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOk(form.result);
  };

  return (
    <div className="bv-create-profit-modal">
      <header>
        <h4>Complete Trade</h4>
      </header>

      <form id="generateTradeForm" className="body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="type" className="form-label">
            Is it a profit or loss?
          </label>

          <select 
            className="form-control"
            name="result" 
            id="result"
            required
            value={form.result || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          >
            <option value="" disabled>Select</option>
            <option value="Profit">Profit</option>
            <option value="Loss">Loss</option>
          </select>
        </div>
      </form>

      <div className="actions">
        <button type="submit" form="generateTradeForm">
          Save
        </button>

        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default CompleteTradeModal;
