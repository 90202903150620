import axios from "axios";
import { API_URL } from "../config/env";

class WalletService {
  static get_addresses = (token) => {
    return axios.get(`${API_URL}/wallets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static update_addresses = (token, data) => {
    return axios.patch(`${API_URL}/wallets`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export default WalletService;
