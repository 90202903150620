import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Withdrawals } from "./Routers/Withdrawals";
import { UpdateBalance } from "./Routers/UpdateBalance";
import { Dashboard } from "./Components/Dashboard";
import { Navbar } from "./Components/Navbar";
import { LoginPage } from "./Components/LoginPage";
import { SideBar } from "./Components/SideBar";
import { User } from "./Routers/User";
import { UserDetails } from "./Routers/UserDetails";
import { UserSettings } from "./Routers/UserSettings";
import { UpdateWallet } from "./Routers/UpdateWallet";
import { RequireAuth } from "./HoC/RequireAuth";
import { NoRequireAuth } from "./HoC/NoRequireAuth";
import "./CSS/sidebar.css";
import "./CSS/table.css";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Redirect from "./HoC/Redirect";
import { useSelector } from "react-redux";
import UserWithdrawalLog from "./Routers/UserWithdrawalLog";
import UserService from "./Services/UserService";
import UserDepositLog from "./Routers/UserDepositLog";
import UserTradeLog from "./Routers/UserTradeLog";

function App() {
  const [Nav, setNav] = useState(false);

  // Nav === true ? showSidebar : hideSidebar
  const toggleSidebar = () => {
    setNav((prevNav) => (prevNav === true ? false : !prevNav));
  };

  const { isLoggedIn, token } = useSelector((state) => state.authSlice);

  const fetchMe = async () => {
    try {
      await UserService.get_me(token);
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      fetchMe();
    }
  }, [token]);

  return (
    <>
      <ToastContainer />

      <div className="App">
        <div className="main-container">
          {isLoggedIn && <SideBar isOpen={Nav} onClose={() => setNav(false)} />}

          <div className="main_nav-bar">
            {isLoggedIn && <Navbar onClick={toggleSidebar} id={Nav} />}

            <Routes>
              <Route page not found />

              <Route index element={<Redirect path="/dashboard" />} />

              <Route
                path="/login"
                element={
                  <NoRequireAuth>
                    <LoginPage />
                  </NoRequireAuth>
                }
              />

              <Route
                path="/users"
                element={
                  <RequireAuth>
                    <User />
                  </RequireAuth>
                }
              />

              <Route
                path="/users/withdraw-log"
                element={
                  <RequireAuth>
                    <UserWithdrawalLog />
                  </RequireAuth>
                }
              />

              <Route
                path="/users/deposit-log"
                element={
                  <RequireAuth>
                    <UserDepositLog />
                  </RequireAuth>
                }
              />

              <Route
                path="/users/trade-log"
                element={
                  <RequireAuth>
                    <UserTradeLog />
                  </RequireAuth>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              >
                <Route path="pending-withdrawals" element={<Withdrawals />} />
                <Route path="pending-deposit" element={<UpdateBalance />} />
              </Route>

              <Route
                path="settings"
                element={
                  <RequireAuth>
                    <UserDetails />
                  </RequireAuth>
                }
              >
                <Route path={"user-settings"} element={<UserSettings />} />
                <Route path="Update-wallet" element={<UpdateWallet />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
