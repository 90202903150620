import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    signOut: (state) => {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
    },
  },
});

export const { signIn, signOut } = authSlice.actions;
export default authSlice.reducer;
