import { useState } from "react";
import "../CSS/update-balance.css";

const UpdateBalanceModal = ({
  handleSubmit,
  initialAmount,
  onClose,
}) => {
  const [amount, setAmount] = useState(initialAmount);

  return (
    <form
      className="bv-update-balance-modal"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(amount);
      }}
    >
      <header>
        <h4>Update User</h4>
      </header>

      <div className="body">
        <div className="form-group">
          <label className="form-label" htmlFor="amount">
            Amount
          </label>

          <input
            type="number"
            step={".01"}
            className="form-control"
            value={amount}
            onChange={(e) => setAmount(e.target.valueAsNumber)}
            min={0.00}
            required
          />
        </div>
      </div>

      <div className="actions">
        <button type="submit" className="update">
          Update
        </button>

        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default UpdateBalanceModal;
