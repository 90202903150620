import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import UserService from "../Services/UserService";
import "../CSS/deposit-log.css";

const UserDepositLog = () => {
  const { users, selectedUser } = useSelector((state) => state.usersSlice);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { token } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const selectedUserData = useMemo(() => {
    return users.filter((user) => user.user_id === selectedUser)[0] || null;
  }, [selectedUser, users]);

  const fetchUserDeposits = async (user_id) => {
    try {
      setLoading(true);
      const res = await UserService.get_user_deposit_log(token, user_id);

      setData(res.data);
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedUser && token) {
      fetchUserDeposits(selectedUser);
    }
  }, [selectedUser, token]);

  if (!selectedUser) return null;

  return (
    <div className="main_routes bv-user-deposit-log">
      <h4>
        Deposit Log For {selectedUserData.first_name} {selectedUserData.last_name}
      </h4>

      {loading && <div className="loading">Loading...</div>}

      {!loading && selectedUser && (
        <div className="responsive-table">
          <table>
            <tr>
              <th>ID</th>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Date</th>
              <th>Status</th>
            </tr>

            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.amount}</td>
                <td>{item.type}</td>
                <td>{new Date(item.created_at).toISOString().split("T")[0]}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default UserDepositLog;
