import React, { useEffect, useState } from "react";
import DepositService from "../Services/DepositService";
import { Input } from "../Components/Input";
import { useServerError } from "../Utils/handleServerError";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import WalletService from "../Services/WalletService";

export const UpdateWallet = () => {
  const [mode, setMode] = useState("view");

  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState([]);

  const { token } = useSelector((state) => state.authSlice);

  const [form, onChange] = useState({});

  const handleChange = (index) => (e) => {
    setWallets(prev => prev.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, address: e.target.value }
      }
      return item;
    }));
  };

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const buttton = {
    marginTop: "2rem",
  };

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3rem",
    marginBottom: "2rem",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "3rem",
    marginBottom: "2rem",
  };

  async function getAddresses() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await WalletService.get_addresses(token);

      if (res.data) {
        setWallets(res.data);
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    getAddresses();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (mode === "view") {
      setMode("edit");

      return;
    }

    if (loading) return;

    setLoading(true);

    try {
      const res = await WalletService.update_addresses(token, {...form, wallets});

      if (res.data) {
        setMode("view");
        toast(res.data.message, { type: "success", theme: "colored" });
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  return (
    <div style={style}>
      <form className="form" style={style} onSubmit={handleSubmit}>
        <div>
          {
            wallets.map((wallet, index) => (
                <div style={{ marginBottom: 16 }} key={'wallet-' + wallet.id}>
                  <Input
                    text={wallet.type}
                    input={"text"}
                    read={mode === "view"}
                    require={true}
                    value={wallet.address}
                    onChange={handleChange(index)}
                  />
                </div>
            ))
          }

          {mode === "edit" && (
            <>
              <div style={{ height: 16 }} />

              <Input
                require={true}
                text={"Enter password"}
                input={"password"}
                name={"password"}
                value={form.password}
                onChange={(e) => onChange(prev => ({...prev, password: e.target.value}))}
              />
            </>
          )}

          <div style={buttonStyle}>
            <button type="submit" style={buttton} disabled={loading}>
              {mode === "view" ? "Edit" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
