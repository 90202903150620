import axios from "axios";
import { API_URL } from "../config/env";

class UserService {
  static get_users = (token) => {
    return axios.get(`${API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static setApprovedStatus = ({status, user_id}, token) => {
    return axios.patch(`${API_URL}/users/approved`, { status, user_id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static change_plan_request_status = (token, data) => {
    return axios.post(`${API_URL}/users/change-plan-request-status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static get_me = (token) => {
    return axios.get(`${API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static remove_user = (token, id) => {
    return axios.delete(`${API_URL}/users?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static update_active_plan = (token, user_id, plan) => {
    return axios.patch(
      `${API_URL}/users/plan`,
      { user_id, plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  static change_balance = (token, data) => {
    return axios.post(`${API_URL}/users/change-balance`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static get_user_withdrawal_log = (token, user_id) => {
    return axios.get(`${API_URL}/users/withdrawal-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static get_user_deposit_log = (token, user_id) => {
    return axios.get(`${API_URL}/users/deposit-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static get_user_trade_log = (token, user_id) => {
    return axios.get(`${API_URL}/users/trade-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export default UserService;
