import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import DepositService from "../Services/DepositService";
import { setDeposits, removeDeposit } from "../Redux/slices/dashboardSlice";
import { toast } from "react-toastify";
import PopModal from "../Components/ModelLayout/ModalLayout";
import SelectDepositAccountModal from "../Components/SelectDepositAccount";

export const UpdateBalance = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.authSlice);
  const { user_deposits } = useSelector((state) => state.dashboardSlice);

  const [isSelectAccountOpen, setIsSelectAccountOpen] = useState(null);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  async function getUserDeposits() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.get_user_deposits(token);

      if (res.data) {
        dispatch(setDeposits(res.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  async function changeStatus(data) {
    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.change_deposit_status(token, data);

      if (res.data) {
        dispatch(removeDeposit(data.id));

        toast(res.data.message, { theme: "colored", type: "success" });
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  }

  const onConfirm = (id) => () => {
    setIsSelectAccountOpen({id});
  }

  useEffect(() => {
    getUserDeposits();
  }, []);

  return (
    <>
      <PopModal modalToggler={isSelectAccountOpen !== null} onClose={() => {}}>
        <SelectDepositAccountModal 
          data={isSelectAccountOpen}
          handleSubmit={changeStatus}
          loading={loading}
          onClose={() => setIsSelectAccountOpen(null)}
        />
      </PopModal>
      <div className="responsive-table">
        <table>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Method</th>
            <th>Proof</th>
            <th>Action</th>
          </tr>

          {user_deposits
            .filter((item) => item.status === "Pending")
            .map((item) => (
              <tr key={item.id}>
                <td>{item.user.first_name}</td>

                <td>{item.user.last_name}</td>

                <td>{new Date(item.created_at).toISOString().split("T")[0]}</td>

                <td>{item.amount}</td>

                <td>{item.type}</td>

                <td>
                  <a href={item.receipt} target="_blank">
                    Check File
                  </a>
                </td>

                <td>
                  <button
                    className={`kyc_action confirm`}
                    disabled={loading}
                    onClick={onConfirm(item.id)}
                  >
                    Confirm
                  </button>

                  <button
                    className={`kyc_action decline`}
                    disabled={loading}
                    onClick={() =>
                      changeStatus({
                        id: item.id,
                        status: "Failed",
                      })
                    }
                  >
                    Decline
                  </button>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </>
  );
};
