import axios from "axios";
import { API_URL } from "../config/env";

class AuthService {
  static login = (data) => {
    return axios.post(`${API_URL}/auth/login`, data);
  };

  static change_password = (token, data) => {
    return axios.post(`${API_URL}/users/reset-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export default AuthService;
