import axios from "axios";
import { API_URL } from "../config/env";

class TradeService {
  static generate_trade = (token, data) => {
    return axios.post(`${API_URL}/trades`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static complete_trade = (token, data) => {
    return axios.post(`${API_URL}/trades/complete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

export default TradeService;
