import axios from "axios";
import { API_URL } from "../config/env";

class DepositService {
  static get_addresses = (tokens) => {
    return axios.get(`${API_URL}/deposit/wallet-addresses`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static update_addresses = (token, data) => {
    return axios.put(`${API_URL}/deposit/wallet-addresses`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static get_user_deposits = (token) => {
    return axios.get(`${API_URL}/deposits`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  static change_deposit_status = (token, data) => {
    return axios.post(`${API_URL}/deposits/change-status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export default DepositService;
