import { useState } from "react";
import "../CSS/confirm-delete.css";

const ConfirmUpdatePlan = ({ handleOk, handleCancel, loading }) => {
  const [plan, setPlan] = useState(""); 

  return (
    <form 
      className="bv-confirm-delete-user"
      onSubmit={(e) => {
        e.preventDefault();
        handleOk(plan);
      }}
    >
      <header>
        <h4>Confirm Update Plan</h4>
      </header>

      <div className="body">
        <select 
          style={{
              width: '100%',
              padding: '8px 16px'
          }}
          value={plan}
          required
          onChange={(e) => setPlan(e.target.value)}
        >
          <option value="" selected disabled>------------- Select -----------</option>
          <option value="Bronze Plan">Bronze Plan</option>
          <option value="Silver Plan">Silver Plan</option>
          <option value="Gold Plan">Gold Plan</option>
          <option value="Platinum Plan">Platinum Plan</option>
        </select>
      </div>

      <div className="actions">
        <button type="submit" disabled={loading}>{loading ? "..." : "Submit"}</button>

        <button onClick={handleCancel}>Cancel</button>
      </div>
    </form>
  );
};

export default ConfirmUpdatePlan;
