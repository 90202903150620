import { useState } from "react";
import "../CSS/update-balance.css";

const SelectDepositAccountModal = ({
  handleSubmit,
  onClose,
  loading,
  data
}) => {
    const [account, setAccount] = useState(""); 

  return (
    <form
        className="bv-update-balance-modal"
        onSubmit={(e) => {
            e.preventDefault();
            handleSubmit({...data, status: "Success", account});
            onClose();
        }}
    >
        <header>
            <h4>Select Account Type</h4>
        </header>

        <div className="body">
            <div className="form-group">
                <select 
                    style={{
                        width: '100%',
                        padding: '8px 16px'
                    }}
                    value={account}
                    required
                    onChange={(e) => setAccount(e.target.value)}
                >
                    <option value="" disabled>Select</option>
                    <option value="normal">Normal</option>
                    <option value="staking">Staking</option>
                </select>
            </div>
        </div>

        <div className="actions">
            <button type="submit" className="update">
                {loading ? "..." : "Update"}
            </button>

            <button type="button" onClick={onClose}>
                Cancel
            </button>
        </div>
    </form>
  );
};

export default SelectDepositAccountModal;
