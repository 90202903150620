import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import UserService from "../Services/UserService";
import "../CSS/profit-log.css";
import CreateTrade from "../Components/CreateTrade";
import PopModal from "../Components/ModelLayout/ModalLayout";
import CompleteTradeModal from "../Components/CompleteTradeModal";
import TradeService from "../Services/TradeService";

const UserTradeLog = () => {
  const { users, selectedUser } = useSelector((state) => state.usersSlice);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { token } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const selectedUserData = useMemo(() => {
    return users.filter((user) => user.user_id === selectedUser)[0] || null;
  }, [selectedUser, users]);

  const fetchUserTrades = async (user_id) => {
    try {
      setLoading(true);
      const res = await UserService.get_user_trade_log(token, user_id);

      setData(res.data);
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedUser && token) {
      fetchUserTrades(selectedUser);
    }
  }, [selectedUser, token]);

  const [createTradeProps, setCreateTradeProps] = useState(null);
  const [completeTradeProps, setCompleteTradeProps] = useState(null);

  const openCompleteTradeModal = (handleOk, handleCancel) => 
    setCompleteTradeProps({ handleOk, handleCancel });

  const openCreateTradeModal = (handleOk, handleCancel) =>
    setCreateTradeProps({ handleOk, handleCancel });

  const closeCreateTradeModal = () => setCreateTradeProps(null);
  const closeCompleteTradeModal = () => setCompleteTradeProps(null);

  const createTrade = () =>
    new Promise((res) => {
      openCreateTradeModal((data) => {
        res(data);
        closeCreateTradeModal();
      }, closeCreateTradeModal);
    });

  const confirmTrade = () =>
    new Promise((res) => {
      openCompleteTradeModal(res, closeCompleteTradeModal);
    });

  const handleAdd = async () => {
    try {
      const trade = await createTrade();

      if (trade) {
        setData((prev) => [trade, ...prev]);
      }
    } catch (error) {}
  };

  const handleComplete = async (trade_id) => {
    try {
      setLoading(true);

      const result = await confirmTrade();

      if (result) {
        const res = await TradeService.complete_trade(token, { trade_id, result });
        setData((prev) => prev.map((trade) => {
          if (trade.id === trade_id) {
            return {...trade, ...res.data};
          }

          return trade;
        }))
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
      closeCompleteTradeModal();
    }
  }

  if (!selectedUser) return null;

  return (
    <>
      {createTradeProps && (
        <PopModal modalToggler={createTradeProps !== null} onClose={() => {}}>
          <CreateTrade
            handleOk={createTradeProps.handleOk}
            handleCancel={createTradeProps.handleCancel}
            user_id={selectedUser}
          />
        </PopModal>
      )}

      {completeTradeProps && <PopModal modalToggler={completeTradeProps !== null} onClose={() => {}}>
        <CompleteTradeModal
          handleOk={completeTradeProps.handleOk}
          handleCancel={completeTradeProps.handleCancel}
        />
      </PopModal>}

      <div className="main_routes bv-user-profit-log">
        <div className="headers">
          <h4>
            Trade Log For {selectedUserData.first_name}{" "}
            {selectedUserData.last_name}
          </h4>

          <button className="btn" onClick={handleAdd}>
            Add
          </button>
        </div>

        {loading && <div className="loading">Loading...</div>}

        {!loading && selectedUser && (
          <div className="responsive-table">
            <table>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Type</th>
                <th>Symbol</th>
                <th>Volume</th>
                <th>Nunber Of Trades</th>
                <th>Status</th>
                <th>Profit</th>
                <th>Loss</th>
                <th>Complete</th>
              </tr>

              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    {new Date(item.created_at).toISOString().split("T")[1]}
                  </td>
                  <td>{item.type}</td>
                  <td>{item.symbol}</td>
                  <td>{item.volume}</td>
                  <td>{item.no_of_trades}</td>
                  <td>{item.status}</td>
                  <td>{item.profit}</td>
                  <td>{item.loss}</td>
                  <td>
                    {item.status === "Pending" && <button
                      className={`kyc_action decline`}
                      onClick={() => handleComplete(item.id)}
                    >
                      Complete
                    </button>}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default UserTradeLog;
