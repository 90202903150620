import { useEffect, useState } from "react";
import "../CSS/create-profit.css";
import { useServerError } from "../Utils/handleServerError";
import { useSelector } from "react-redux";
import TradeService from "../Services/TradeService";

const CreateTrade = ({ handleOk, handleCancel, user_id }) => {
  const [form, setForm] = useState({});

  const { token } = useSelector((state) => state.authSlice);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  useServerError(error);

  const openErrorMessage = (error) => {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  };

  const onChange = (key, val) => setForm((prev) => ({ ...prev, [key]: val }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token || !form.type || !form.symbol || !form.volume || !form.no_of_trades) {
      return;
    }

    setLoading(true);

    try {
      const res = await TradeService.generate_trade(token, {
        ...form,
        user_id,
        volume: Number(form.volume)
      });

      handleOk(res.data || null);
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (form.type) {
      onChange("symbol", "");
    }
  }, [form.type]);

  return (
    <div className="bv-create-profit-modal">
      <header>
        <h4>Generate Trade</h4>
      </header>

      <form id="generateTradeForm" className="body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="type" className="form-label">
            Type
          </label>

          <select 
            className="form-control"
            name="type" 
            id="type"
            required
            value={form.type || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          >
            <option value="" disabled>Select</option>
            <option value="Crypto">Crypto</option>
            <option value="Forex">Forex</option>
            <option value="Stocks">Stocks</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="symbol" className="form-label">
            Symbol
          </label>

          <select 
            className="form-control"
            name="symbol" 
            id="symbol"
            required
            value={form.symbol || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          >
            <option value="" disabled>Select</option>

            {form.type === "Forex" && (
              <>
                <option value="EURUSD">EUR/USD</option>
                <option value="GBPUSD">GBP/USD</option>
                <option value="USDJPY">USD/JPY</option>
                <option value="AUDUSD">AUD/USD</option>
                <option value="USDCAD">USD/CAD</option>
                <option value="USDCHF">USD/CHF</option>
                <option value="EURGBP">EUR/GBP</option>
                <option value="EURJPY">EUR/JPY</option>
                <option value="GBPJPY">GBP/JPY</option>
                <option value="AUDJPY">AUD/JPY</option>
                <option value="NZDUSD">NZD/USD</option>
                <option value="EURAUD">EUR/AUD</option>
                <option value="USDCNH">USD/CNH</option>
                <option value="USDHKD">USD/HKD</option>
                <option value="USDSGD">USD/SGD</option>
              </>
            )}
            
            {form.type === "Crypto" && (
                <>
                  <option value="BTC-ETH">BTC/ETH (Bitcoin/Ethereum)</option>
                  <option value="BTC-XRP">BTC/XRP (Bitcoin/Ripple)</option>
                  <option value="BTC-LTC">BTC/LTC (Bitcoin/Litecoin)</option>
                  <option value="ETH-XRP">ETH/XRP (Ethereum/Ripple)</option>
                  <option value="ETH-LTC">ETH/LTC (Ethereum/Litecoin)</option>
                  <option value="XRP-LTC">XRP/LTC (Ripple/Litecoin)</option>
                  <option value="BTC-BCH">BTC/BCH (Bitcoin/Bitcoin Cash)</option>
                  <option value="ETH-BCH">ETH/BCH (Ethereum/Bitcoin Cash)</option>
                  <option value="XRP-BCH">XRP/BCH (Ripple/Bitcoin Cash)</option>
                  <option value="BTC-ADA">BTC/ADA (Bitcoin/Cardano)</option>
                  <option value="ETH-ADA">ETH/ADA (Ethereum/Cardano)</option>
                  <option value="XRP-ADA">XRP/ADA (Ripple/Cardano)</option>
                  <option value="LTC-ADA">LTC/ADA (Litecoin/Cardano)</option>
                  <option value="BCH-ADA">BCH/ADA (Bitcoin Cash/Cardano)</option>
                  <option value="BTC-DOGE">BTC/DOGE (Bitcoin/Dogecoin)</option>
                </>
            )}

            {form.type === "Stocks" && (
              <>
                <option value="AAPL-MSFT">AAPL/MSFT (Apple/Microsoft)</option>
                <option value="GOOGL-AMZN">GOOGL/AMZN (Alphabet/Amazon)</option>
                <option value="FB-TWTR">FB/TWTR (Facebook/Twitter)</option>
                <option value="NFLX-DIS">NFLX/DIS (Netflix/Disney)</option>
                <option value="TSLA-NVDA">TSLA/NVDA (Tesla/NVIDIA)</option>
                <option value="JPM-GS">JPM/GS (JPMorgan Chase/Goldman Sachs)</option>
                <option value="IBM-INTC">IBM/INTC (IBM/Intel)</option>
                <option value="BAC-WFC">BAC/WFC (Bank of America/Wells Fargo)</option>
                <option value="KO-PEP">KO/PEP (Coca-Cola/PepsiCo)</option>
                <option value="WMT-TGT">WMT/TGT (Walmart/Target)</option>
                <option value="UNH-CVS">UNH/CVS (UnitedHealth/CVS Health)</option>
                <option value="MMM-HON">MMM/HON (3M/Honeywell)</option>
                <option value="COST-AMZN">COST/AMZN (Costco/Amazon)</option>
                <option value="PG-JNJ">PG/JNJ (Procter & Gamble/Johnson & Johnson)</option>
                <option value="HD-LOW">HD/LOW (Home Depot/Lowe's)</option>
              </>
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="volume" className="form-label">
            Volume
          </label>

          <input
            className="form-control"
            type="number"
            id="volume"
            required
            step={"0.01"}
            name="volume"
            value={form.volume || ""}
            onChange={(e) => onChange(e.target.name, e.target.valueAsNumber)}
            min={0.00}
          />
        </div>

        <div className="form-group">
          <label htmlFor="no_of_trades" className="form-label">
            Number Of Trades
          </label>

          <input
            className="form-control"
            type="number"
            id="no_of_trades"
            required
            name="no_of_trades"
            value={form.no_of_trades || ""}
            onChange={(e) => onChange(e.target.name, e.target.valueAsNumber)}
            min={1}
          />
        </div>
      </form>

      <div className="actions">
        <button type="submit" disabled={loading} form="generateTradeForm">
          {loading ? "..." : "Save"}
        </button>

        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default CreateTrade;
