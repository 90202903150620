import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import WithdrawService from "../Services/WithdrawService";
import { setWithdraws, removeWithdraw } from "../Redux/slices/dashboardSlice";
import { toast } from "react-toastify";
import "../CSS/kyc.css";

export const Withdrawals = () => {
  const style = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    paddingBottom: "3rem",
    overflow: "hidden",
    width: "100%",
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.authSlice);
  const { user_withdraws } = useSelector((state) => state.dashboardSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  async function getUserWithdraws() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await WithdrawService.get_user_withdraws(token);

      if (res.data) {
        dispatch(setWithdraws(res.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  async function changeStatus(data) {
    if (loading) return;

    setLoading(true);

    try {
      const res = await WithdrawService.change_withdrawal_status(token, data);

      if (res.data) {
        dispatch(removeWithdraw(data.id));

        toast(res.data.message, { theme: "colored", type: "success" });
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  }

  useEffect(() => {
    getUserWithdraws();
  }, []);

  return (
    <div className="responsive-table">
      <table>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Method</th>
          <th>Wallet Address</th>
          <th>Account Number</th>
          <th>Account Name</th>
          <th>Bank Name</th>
          <th>Swift Code</th>
          <th>Routing Number</th>
          <th>Action</th>
        </tr>

        {user_withdraws
          .filter((item) => item.status === "Pending")
          .map((item) => (
            <tr key={item.id}>
              <td>{item.user.first_name}</td>

              <td>{item.user.last_name}</td>

              <td>{new Date(item.created_at).toISOString().split("T")[0]}</td>

              <td>{item.amount}</td>

              <td>{item.type}</td>

              <td>{item.wallet_address || "N/A"}</td>

              <td>{item.account_no || "N/A"}</td>

              <td>{item.account_name || "N/A"}</td>

              <td>{item.bank_name || "N/A"}</td>

              <td>{item.swift_code || "N/A"}</td>

              <td>{item.routing_number || "N/A"}</td>

              <td>
                <button
                  className={`kyc_action confirm`}
                  disabled={loading}
                  onClick={() =>
                    changeStatus({
                      id: item.id,
                      status: "Success",
                    })
                  }
                >
                  Confirm
                </button>

                <button
                  className={`kyc_action decline`}
                  disabled={loading}
                  onClick={() =>
                    changeStatus({
                      id: item.id,
                      status: "Failed",
                    })
                  }
                >
                  Decline
                </button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};
